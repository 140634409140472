import { useState } from "react";
import {
    useMutation,
    MutationHookOptions,
    OperationVariables,
    MutationTuple,
    MutationResult,
    MutationFunctionOptions
} from "react-apollo";
import { DocumentNode } from "graphql";
import { ApolloError } from "apollo-client";

import { newMothershipApolloClient } from "../graphql/clients";

type IResettableMutationState<TData = any> = Pick<MutationResult<TData>, "data" | "called" | "loading" | "error">;

export function useMothershipMutation<TData = any, TVariables = OperationVariables>(
    mutation: DocumentNode,
    options: MutationHookOptions<TData, TVariables> = {}
): [MutationTuple<TData, TVariables>[0], MutationResult<TData> & { reset: () => void }] {
    const [{ loading, data, error, called }, setState] = useState<IResettableMutationState>({
        called: false,
        loading: false
    });

    const reset = () => {
        setState({ data: undefined, loading: false, called: false, error: undefined });
    };

    const [mutate, { client }] = useMutation<TData, TVariables>(mutation, {
        ...options,
        client: newMothershipApolloClient,
        onCompleted: undefined,
        onError: undefined
    });

    const mutateWrapper = async (opts?: MutationFunctionOptions<TData, TVariables>) => {
        try {
            setState({ data: undefined, loading: true, called: true, error: undefined });

            const response = await mutate(opts);

            setState({ data: response.data, loading: false, called: true, error: undefined });
            options.onCompleted && options.onCompleted(response.data as TData);
            return response;
        } catch (error) {
            setState({ data: undefined, loading: false, called: true, error: error as ApolloError });
            options.onError && options.onError(error as ApolloError);

            throw error;
        }
    };

    return [mutateWrapper, { data, loading, error, called, client, reset }];
}
