import { useQuery, QueryHookOptions, OperationVariables } from "react-apollo";
import { DocumentNode } from "graphql";

import { typedApolloClient } from "../graphql/clients";

export function useTypedQuery<TData = any, TVariables = OperationVariables>(
    query: DocumentNode,
    options?: QueryHookOptions<TData, TVariables>
) {
    return useQuery(query, {
        ...options,
        client: typedApolloClient,
        fetchPolicy: "network-only"
    });
}
