import { FieldErrors } from "react-hook-form";

import { useUpdateEffect } from "./useUpdateEffect";

/**
 * Hook to scroll and focus on the first form field with an error.
 *
 * @param errors - An object containing form errors, typically from react-hook-form.
 * @example
 * const { register, handleSubmit, formState: { errors } } = useForm();
 * useScrollToError(errors);
 */
export const useScrollToError = <TFieldValues extends Record<string, any>>(errors: FieldErrors<TFieldValues>): void => {
    useUpdateEffect(() => {
        const hasErrors = Object.keys(errors);

        if (hasErrors.length > 0) {
            const firstErrorElement = Object.values(errors)[0].ref as HTMLElement;

            if (firstErrorElement) {
                firstErrorElement.focus();
            }
        }
    }, [errors]);
};
