import { useEffect, useRef } from "react";

export const useInterval = (callback: Function, delay?: number | null) => {
    const savedCallback = useRef<Function>(() => {});

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        if (!delay !== null) {
            const id = setInterval(() => savedCallback.current(), delay || 0);
            return () => clearInterval(id);
        }

        return undefined;
    }, [delay]);
};
