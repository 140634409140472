import { useMemo } from "react";

import { setRef } from "FunctionUtils";

export function useForkRef(refA: React.Ref<any> | null, refB: React.Ref<any> | null) {
    return useMemo(() => {
        if (refA == null && refB == null) {
            return null;
        }

        return (refValue: any) => {
            setRef(refA, refValue);
            setRef(refB, refValue);
        };
    }, [refA, refB]);
}
