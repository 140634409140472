import { useEffect, useRef } from "react";

export const useStartStopInterval = (callback: () => void, interval: number) => {
    const savedCallback = useRef<() => void>();
    const timerId = useRef<number>();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    const start = () => {
        if (timerId && timerId.current) {
            clear();
        }
        function tick() {
            savedCallback.current && savedCallback.current();
        }
        timerId.current = window.setInterval(tick, interval);
    };

    const clear = () => {
        window.clearInterval(timerId.current);
    };

    return [start, clear];
};
