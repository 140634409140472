import { useMemo } from "react";

import { Menu, MenuProductCategory, Unwrap } from "Types";
import { composeFilters } from "Utils";
import { filterUpsellCategories, menuProductCategoryHasProducts } from "Filters";

export const getActiveMenuProductCategories = (
    menuProductCategories: MenuProductCategory[],
    hideFromStockIds: Set<string>
) => {
    if (menuProductCategories && hideFromStockIds) {
        const applyFilters = composeFilters<Unwrap<typeof menuProductCategories>>(category =>
            menuProductCategoryHasProducts(category, hideFromStockIds)
        );
        return applyFilters(menuProductCategories);
    }

    return [];
};

export const useActiveMenuProductCategories = (menu: Menu, hideFromStockIds: Set<string>) => {
    return useMemo(() => {
        if (menu && hideFromStockIds) {
            const applyFilters = composeFilters<Unwrap<typeof menu.menuProductCategories>>(
                filterUpsellCategories,
                category => menuProductCategoryHasProducts(category, hideFromStockIds)
            );
            return applyFilters(menu.menuProductCategories);
        }
        return [];
    }, [menu, hideFromStockIds]);
};
