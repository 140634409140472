import { useState, useEffect, useMemo } from "react";

export type OrientationState = {
    angle: number;
    type: ScreenOrientation["type"] | "";
};

const defaultState: OrientationState = {
    type: "landscape-primary",
    angle: 0
};

export const useOrientation = (initialState: OrientationState = defaultState): OrientationState => {
    const [state, setState] = useState<OrientationState>(initialState);

    useEffect(() => {
        const screen = window.screen;
        let mounted = true;

        const onOrientationChange = () => {
            if (mounted) {
                const { orientation } = screen as any;

                if (orientation) {
                    const { angle, type } = orientation;
                    setState({
                        angle,
                        type
                    });
                } else if (window.orientation) {
                    setState({
                        angle: typeof window.orientation === "number" ? window.orientation : 0,
                        type: ""
                    });
                } else {
                    setState(initialState);
                }
            }
        };

        window.addEventListener("orientationchange", onOrientationChange);
        onOrientationChange();

        return () => {
            mounted = false;
            window.removeEventListener("orientationchange", onOrientationChange);
        };
    }, []);

    return useMemo(() => state, [state.angle, state.type]);
};
