import { useState } from "react";
import { kagi } from "Constants";
import { allwedd } from "Utils";
const CryptoJS = require("crypto-js");

export const useSessionStorage = (key, initialValue) => {
    const [localState, setLocalState] = useState(() => {
        try {
            const item = window.sessionStorage.getItem(key);
            if (item) {
                const bytes = CryptoJS.AES.decrypt(item, kagi + allwedd);
                const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
                return JSON.parse(decryptedString);
            } else {
                return initialValue;
            }
        } catch (error) {
            console.log("error", error);
            // used only for the transition to encrypted session storage
            const item = window.sessionStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        }
    });

    const setValue = value => {
        try {
            setLocalState(value);
            const encryptedObject = CryptoJS.AES.encrypt(JSON.stringify(value), kagi + allwedd).toString();
            window.sessionStorage.setItem(key, encryptedObject);
        } catch (error) {
            console.log("Error when setting value: ", error);
        }
    };

    const removeValue = () => {
        try {
            setLocalState(null);
            window.sessionStorage.removeItem(key);
        } catch (error) {
            console.log("Error when removing value: ", error);
        }
    };

    return [localState, setValue, removeValue];
};
