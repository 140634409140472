import { useEffect, useState } from "react";

export const useToggle = delay => {
    const [state, setState] = useState(false);
    useEffect(() => {
        const timeout = setTimeout(() => {
            setState(false);
        }, delay);
        return () => clearTimeout(timeout);
    }, [state]);
    const setTrue = () => setState(true);
    return [state, setTrue];
};
