import { useCallback, useEffect, useRef } from "react";

/**
 * React hook to persist any value between renders,
 * but keeps it up-to-date if it changes.
 *
 */
export const useCallbackRef = <T extends (...args: any[]) => any>(
    callback: T | undefined,
    deps: React.DependencyList = []
) => {
    const callbackRef = useRef(callback);

    useEffect(() => {
        callbackRef.current = callback;
    });

    return useCallback(((...args) => callbackRef.current?.(...args)) as T, deps);
};
