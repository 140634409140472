import { useRef, useEffect } from "react";

/**
 * Hook to keep track of the prev value
 * @param {any} - Value you want to keep track of
 * @return {any} - The prev value
 */
export const usePrev = value => {
    const ref = useRef();

    useEffect(() => {
        ref.current = value;
    }, [value]);

    return ref.current;
};
