import { isSafari } from "react-device-detect";

import { useEventListener } from "CoreHooks";

export interface UseFocusOnMouseDownProps {
    enabled?: boolean;
    ref: React.RefObject<HTMLElement>;
    elements?: Array<React.RefObject<HTMLElement> | HTMLElement | null>;
}

function isRefObject(val: any): val is { current: any } {
    return "current" in val;
}

/**
 * Polyfill to get `relatedTarget` working correctly consistently
 * across all browsers.
 *
 * It ensures that elements receives focus on pointer down if
 * it's not the active element.
 *
 * Thanks ChatGPT
 */
export const useFocusOnPointerDown = (props: UseFocusOnMouseDownProps) => {
    const { ref, elements, enabled } = props;

    const doc = () => ref.current?.ownerDocument ?? window.document;

    useEventListener(doc, "pointerdown", event => {
        if (!isSafari || !enabled) return;
        const target = event.target as HTMLElement;

        const els = elements ?? [ref];
        const isValidTarget = els.some(elementOrRef => {
            const el = isRefObject(elementOrRef) ? elementOrRef.current : elementOrRef;
            return el?.contains(target) || el === target;
        });

        if (doc().activeElement !== target && isValidTarget) {
            event.preventDefault();
            target.focus();
        }
    });
};
