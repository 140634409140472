import { useState, useEffect } from "react";

/** Needs to be redone */
export const useTimeInterval = (newTime) => {
    const [time, setTime] = useState(newTime);

    useEffect(() => {
        let timeId = setInterval(() => {
            setTime(newTime);
        }, 1000);
        return () => clearInterval(timeId);
    });
    return time;
};
