import { useState } from "react";
const CryptoJS = require("crypto-js");

import { kagi } from "Constants";
import { smallMobileScreen } from "Utils";

export const getLocalStorageItem = (key, initialValue) => {
    try {
        const item = window.localStorage.getItem(key);

        if (item) {
            const bytes = CryptoJS.AES.decrypt(item, kagi + smallMobileScreen);
            const decryptedString = bytes.toString(CryptoJS.enc.Utf8);
            return JSON.parse(decryptedString);
        } else {
            throw "No item found, returning initial value";
        }
    } catch (e) {
        return initialValue;
    }
};

/** Note: This is in the test suite */
export const useLocalStorage = (key, initialValue) => {
    const [localState, setLocalState] = useState(() => {
        try {
            return getLocalStorageItem(key, initialValue);
        } catch (error) {
            console.log("error", error);
            return initialValue;
        }
    });

    const setValue = value => {
        try {
            setLocalState(value);
            const item = CryptoJS.AES.encrypt(JSON.stringify(value), kagi + smallMobileScreen).toString();
            window.localStorage.setItem(key, item);
        } catch (error) {
            console.log("Error when setting value: ", error);
        }
    };

    const removeValue = () => {
        try {
            setLocalState(null);
            window.localStorage.removeItem(key);
        } catch (error) {
            console.log("Error when removing value: ", error);
        }
    };

    return [localState, setValue, removeValue];
};
