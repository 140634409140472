import { useEffect } from "react";

/**
 *
 * @deprecated use useEventListener from Hooks instead
 */
export const useEventCallback = ({ eventName, callback, element = window, active = true }) => {
    useEffect(() => {
        if (active) {
            element.addEventListener(eventName, callback);

            return () => {
                element.removeEventListener(eventName, callback);
            };
        }
    }, [eventName, callback, element, active]);
};
