import { useState, useCallback } from "react";

export const useSwitch = (initialState: boolean = false) => {
    const [open, setOpen] = useState(initialState);

    const onOpen = useCallback(() => setOpen(true), []);
    const onClose = useCallback(() => setOpen(false), []);
    const onToggle = useCallback(() => setOpen(curr => !curr), []);

    return { open, onOpen, onClose, onToggle };
};
