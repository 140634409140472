import { useState, useEffect, useCallback } from "react";

export const useCountDown = (seconds: number) => {
    const [timeLeft, setTimeLeft] = useState(seconds);

    useEffect(() => {
        const id = setInterval(() => {
            setTimeLeft(current => {
                if (current <= 0) {
                    clearInterval(id);
                    return 0;
                }
                return timeLeft - 1;
            });
        }, 1000);

        return () => clearInterval(id);
    }, [timeLeft]);

    const resetTimeLeft = useCallback(() => {
        setTimeLeft(seconds);
    }, [seconds]);

    return { timeLeft, resetTimeLeft };
};
