import { QueryHookOptions, OperationVariables, useLazyQuery } from "react-apollo";
import { DocumentNode } from "graphql";

import { newMothershipApolloClient } from "../graphql/clients";

export function useMothershipLazyQuery<TData = any, TVariables = OperationVariables>(
    query: DocumentNode,
    options?: QueryHookOptions<TData, TVariables>
) {
    return useLazyQuery(query, {
        ...options,
        client: newMothershipApolloClient,
        fetchPolicy: "network-only"
    });
}
