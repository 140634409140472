import { useState } from "react";
import Cookies, { CookieSetOptions, Cookie } from "universal-cookie";

import { CookieCustomerConsent, CookieKeys, CookieKeyToConsentType, COOKIE_CONSENT_TYPES } from "Types";
import { useEffectOnce } from "CoreHooks";
import { frontyEnv, envConstants } from "Constants";

type CookieConsentReturn = {
    hasValidCookieConsent: boolean;
    customerConsentsToAll: boolean;
    selectedCookie: any;
    allowSiteTracking: boolean;
    hasFunctionalCookieConsent: boolean;
    setConsentCookie: (consent: CookieCustomerConsent, options?: CookieSetOptions) => void;
    setSelectedCookie: (value: any, options: CookieSetOptions) => void;
    removeSelectedCookie: () => void;
    onSetPartialConsent: (consentKey: COOKIE_CONSENT_TYPES | COOKIE_CONSENT_TYPES[], hasConsented: boolean) => void;
    removeAccessToken: () => void;
};

type CookieConsentHook = (key?: CookieKeys) => CookieConsentReturn;

const defaultCookieConsent: CookieCustomerConsent = {
    essential: true,
    functional: false,
    analytics: false,
    marketing: false
};

/** Default period is one year */
const defaultCookieOptions: CookieSetOptions = {
    expires: new Date(Date.now() + 31536000000),
    domain: location.hostname,
    path: "/"
};

export const useCookieConsent: CookieConsentHook = (key?: CookieKeys) => {
    const cookies = new Cookies();

    const [consentCookie, setConsentCookie] = useState<CookieCustomerConsent | null>(() => {
        const consent = cookies.get(CookieKeys.ONLINE_COOKIE_CONSENT) ?? null;
        if (!!consent) {
            return consent as CookieCustomerConsent;
        }
        return null;
    });

    const customerConsents = !!consentCookie && Object.values(consentCookie).every(value => value);

    /**
     * [FUNCTION] Checks if cookie has consent from the customer
     * - checks the consent cookie if the key and it's matching consent type is true or false
     * @param cookiekey
     * @returns {boolean} true / false
     */
    const selectedCookieHasConsent = (cookiekey: CookieKeys): Cookie | null => {
        // Step 1 get cookie key consent from key passed in
        // ** each cookie key has the consent type  in cookieKeyToConsent
        const consentType = CookieKeyToConsentType[cookiekey] ?? null;
        // Step 2 get latest cookie
        const reCheckConsentCookie = (cookies.get(CookieKeys.ONLINE_COOKIE_CONSENT) as CookieCustomerConsent) ?? null;
        // Step 3 if consent Cookie & type found check the consent and has the customer given consent
        if (!!reCheckConsentCookie && !!consentType) {
            const cookieHasConsent = reCheckConsentCookie[consentType as COOKIE_CONSENT_TYPES];
            return cookieHasConsent;
            // Step 4 else if consent type is there and if it is essential - can save cookie
        } else if (!!consentType && consentType === COOKIE_CONSENT_TYPES.ESSENTIAL) {
            return true;
        }
        return false;
    };

    const [selectedCookie, setSelectedCookie] = useState(() => {
        const cookie = key ? cookies.get(key) : null;
        if (!!cookie) {
            return cookie;
        }
        return null;
    });

    /**
     * [FUNCTION] remove google analytics
     * Uses the consent from analytics part of the consent
     */
    const onRemoveAnalyticCookies = () => {
        if (!!consentCookie && !consentCookie.analytics) {
            const options = { path: "/", domain: location.hostname };
            cookies.remove("_ga", options);
            cookies.remove("_gat", options);
            cookies.remove("_gid", options);
        }
    };

    /**
     * [FUNCTION]
     * - Passes in the consent cookie
     * If customer changes their consent on cookies, it will go through each cookie and it's consent type
     * as well as match to the actual consent given - if false will deleted the cookie
     * @param consent
     */
    const onDeleteCookiesWithoutConsent = (consent: CookieCustomerConsent) => {
        const options = { path: "/", domain: location.hostname };

        Object.entries(CookieKeyToConsentType).forEach(([cookieKey, consentType]) => {
            if (consentType) {
                const hasConsent = consentType === COOKIE_CONSENT_TYPES.ESSENTIAL ? true : consent[consentType];
                if (!hasConsent) {
                    cookies.remove(cookieKey, options);
                }
            }
        });
        onRemoveAnalyticCookies();
    };

    /**
     * [FUNCTION] to set consent cookie
     * @param consent CookieCustomerConset type
     * @param options Options from universal cookie (CookieSetOptions)
     */
    const onSetConsentCookie = (consent: CookieCustomerConsent, options?: CookieSetOptions) => {
        cookies.set(CookieKeys.ONLINE_COOKIE_CONSENT, consent, options ? options : defaultCookieOptions);
        onDeleteCookiesWithoutConsent(consent);
        setConsentCookie(consent);
    };

    /**
     * [FUNCTION] sets the current selected cookie from the key passed into the hook
     * @param value
     * @param options
     */
    const onSetSelectedCoookie = (value: any, options: CookieSetOptions) => {
        if (key) {
            const customerHasConsented = selectedCookieHasConsent(key);
            if (customerHasConsented) {
                cookies.set(key, value, options);
                setSelectedCookie(value);
            } else {
                onRemoveSelectedCookie();
                setSelectedCookie(null);
            }
        }
    };

    /**
     * [FUNCTION] partially sets the consent cookie
     * e.g if a customer checks the box or unchecks the box on checkout form it will set or remove those cookies
     * in the example it will be marketing & saving personal data in memory
     * @param consentKey
     * @param hasConsented
     */
    const onSetPartialConsent = (consentKey: COOKIE_CONSENT_TYPES | COOKIE_CONSENT_TYPES[], hasConsented: boolean) => {
        const newConsentCookie =
            (cookies.get(CookieKeys.ONLINE_COOKIE_CONSENT) as CookieCustomerConsent) ?? defaultCookieConsent;
        if (Array.isArray(consentKey)) {
            consentKey.forEach(key => Reflect.set(newConsentCookie, key, hasConsented));
        } else {
            Reflect.set(newConsentCookie, consentKey, hasConsented);
        }

        cookies.set(CookieKeys.ONLINE_COOKIE_CONSENT, newConsentCookie, defaultCookieOptions);
        onDeleteCookiesWithoutConsent(newConsentCookie);
        onSetConsentCookie(newConsentCookie);
    };

    const onRemoveSelectedCookie = () => {
        if (key) {
            cookies.remove(key, { path: "/", domain: location.hostname });
        }
    };
    const onRemoveJwtAccessToken = () => {
        const isLocalEnviroment = envConstants.PROFILE === frontyEnv.LOCAL;
        const cookiesDomain = isLocalEnviroment ? location.hostname : "qopla.com";
        cookies.remove(CookieKeys.JWT_ACCESS_TOKEN, { path: "/", domain: cookiesDomain });
    };

    useEffectOnce(() => {
        onRemoveAnalyticCookies();
    });

    return {
        hasValidCookieConsent: !!consentCookie,
        customerConsentsToAll: customerConsents,
        selectedCookie,
        allowSiteTracking: !!consentCookie && consentCookie.analytics,
        hasFunctionalCookieConsent: !!consentCookie && consentCookie.functional,
        setConsentCookie: onSetConsentCookie,
        setSelectedCookie: onSetSelectedCoookie,
        removeSelectedCookie: onRemoveSelectedCookie,
        removeAccessToken: onRemoveJwtAccessToken,
        onSetPartialConsent
    };
};
