import { useState } from "react";
import moment from "moment";
import { isMobile } from "react-device-detect";

import { useEffectOnce, useLocalStorage } from "CoreHooks";
import { isInStandalone } from "Utils";

type IUseShouldPromptToInstall = {
    isForAdmin?: boolean;
    dayCount?: number;
};

export const useShouldPromptToInstall = ({ isForAdmin = false, dayCount = 30 }: IUseShouldPromptToInstall) => {
    const [shouldPromptToInstall, setShouldPromptToInstall] = useState(false);

    const storageItem = isForAdmin ? "lastInstallPromptAdmin" : "lastInstallPromptCustomer";
    const [lastPrompt, setLastPrompt] = useLocalStorage(storageItem, null);

    useEffectOnce(() => {
        const _isInStandalone = isInStandalone();

        if (!_isInStandalone && isMobile) {
            const now = moment().toDate();
            const days = moment(now).diff(lastPrompt, "days");

            const hasSurpassedDayCount = isNaN(days) || days > dayCount;
            const shouldPrompt = !!hasSurpassedDayCount;

            if (shouldPrompt) {
                setLastPrompt(now.toISOString());
                setShouldPromptToInstall(true);
            }
        }
    });

    return shouldPromptToInstall;
};
