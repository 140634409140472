import { ScaleInterface, SerialScaleAPI } from "../devices/scales/ScaleInterfaceAPI";
// Usages
// useWeightScale(Stathmos3070);

export const useWeightScale = (scaleInterface: ScaleInterface) => {
    SerialScaleAPI.scaleInterface = scaleInterface;
    return {
        readWeight: SerialScaleAPI.readWeight,
        isReady: SerialScaleAPI.isReady,
        isConnected: () => SerialScaleAPI.isConnected,
        connect: SerialScaleAPI.acquireConnection
    };
};
