import { BaseMutationOptions, useMutation } from "react-apollo";

import { ACCEPT_CATERING_ORDER, DECLINE_CATERING_ORDER, CANCEL_CATERING_ORDER } from "GraphQLMutations";

export const useUpsertCateringOrder = (orderId: string, updateFunc?: BaseMutationOptions["update"]) => {
    const [acceptCateringOrder] = useMutation(ACCEPT_CATERING_ORDER, {
        variables: { orderId },
        update: updateFunc
    });
    const [declineCateringOrder] = useMutation(DECLINE_CATERING_ORDER, {
        variables: { orderId },
        update: updateFunc
    });

    const [cancelCateringOrder] = useMutation(CANCEL_CATERING_ORDER, {
        variables: { orderId },
        update: updateFunc
    });

    return [acceptCateringOrder, declineCateringOrder, cancelCateringOrder];
};
