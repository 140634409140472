import { ONE_MINUTE } from "Constants";
import { GET_ORDER_WINDOW_FOR_SHOP } from "GraphQLQueries";
import { useMothershipLazyQuery } from "Hooks";
import { usePosStore, useTableStore, useQoplaStore } from "Stores";

export const useFetchOrderWindowsForShop = () => {
    const { selectedShop } = useQoplaStore();
    // shouldPollFromPos is taken from the check for multiple paired POS - if the shop has multiple paired POS, we give the option to poll
    const { selectedPos, shouldPollFromPos } = usePosStore();
    const { hasShopTables } = useTableStore();

    // We only poll if shop has tables or if POS allows parked orders
    const allowPhonePostponeOrders = selectedPos?.allowPhonePostponeOrders;
    // If the shop doesn't have multiple paired POS, we don't need to poll
    const shouldPoll = shouldPollFromPos ? allowPhonePostponeOrders || hasShopTables : false;

    return useMothershipLazyQuery(GET_ORDER_WINDOW_FOR_SHOP, {
        variables: { shopId: selectedShop?.id },
        pollInterval: shouldPoll ? ONE_MINUTE : undefined
    });
};
