import { useEffect } from "react";

export const useOutsideClick = ({
    ref,
    callback,
    active = true
}: {
    ref: any;
    callback: (event: any) => void;
    active: boolean;
}) => {
    useEffect(() => {
        const handleClick = (e: any) => {
            if (!ref.current || ref.current.contains(e.target)) {
                return;
            }

            callback(e);
        };

        if (active) {
            document.addEventListener("mousedown", handleClick);
            document.addEventListener("touchstart", handleClick);
        }

        return () => {
            document.removeEventListener("mousedown", handleClick);
            document.removeEventListener("touchstart", handleClick);
        };
    }, [ref, callback]);
};
