import confetti from "canvas-confetti";

import { useEffectOnce } from "./useEffectOnce";

export type UseConfetti = {
    canvas: HTMLCanvasElement;
    confettiOptions?: confetti.Options;
    startManually?: boolean;
};

export const useConfetti = ({ canvas, confettiOptions, startManually = true }: UseConfetti) => {
    const _confetti = confetti.create(canvas, { resize: true, useWorker: true });

    useEffectOnce(() => {
        return () => _confetti.reset();
    });

    if (startManually) {
        return () => _confetti(confettiOptions);
    } else {
        return _confetti(confettiOptions);
    }
};
